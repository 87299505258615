import { getServiceDetails } from '../common/utils'
import {
	handleAxiosHTTPException
} from "WebUI_Framework";

import { getBucketNames } from './cacheBuckets';

let CommonServiceRoutines = {};

CommonServiceRoutines.partnerList = async (serverInstance, accessToken, parentOrgId, searchText, partnerRelationshipType) => {
	let response;
	let params = {};
	if (parentOrgId) {
		params.parent_org_id = parentOrgId;
	}
	if (searchText && searchText !== '') {
		params.org_name = searchText;
	}
	if (partnerRelationshipType) {
		params.partner_relationship_type = partnerRelationshipType;
	}

	try {
		response = await serverInstance.get(`biop/subscriber/masters/partnerlist`,
			{
				params,
				headers: {
					"X-Auth": accessToken
				}
			}
		);

		return response.data;
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
}

CommonServiceRoutines.fetchPartners = async (session, parentOrgId, partnerRelationshipType) => {
    {
        let list = session.getFromSessionCache(getBucketNames().COMPANIES_PARTNER_LISTS, parentOrgId+partnerRelationshipType);
        if (list) {
            return list;
        }
    }

	const list = await CommonServiceRoutines.partnerList(session.getServerInstance('SUBSCRIBER'),
								session.getAccessToken(), parentOrgId, '', partnerRelationshipType);

	session.setInSessionCache(getBucketNames().COMPANIES_PARTNER_LISTS, parentOrgId+partnerRelationshipType, list);

    return list;
}

CommonServiceRoutines.companyDetails = async (serverInstance, accessToken, orgId) => {
	let response;
	try {
		response = await serverInstance.get(`biop/registrar/company/fetch?org_id=${ orgId }`,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);

		return response.data;
	}
	catch(e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
}

CommonServiceRoutines.fetchCompany = async (session, orgId) => {

	let orgDetails = session.getFromSessionCache(getBucketNames().COMPANIES, orgId);
	if (orgDetails) {
		//console.log("CACHED DATA");
		return orgDetails;
	}

	orgDetails = await CommonServiceRoutines.companyDetails(session.getServerInstance('ROC'),
										session.getAccessToken(), orgId);

	session.setInSessionCache(getBucketNames().COMPANIES, orgId, orgDetails);

	return orgDetails;
}

CommonServiceRoutines.getAddress = async(serverInstance, accessToken, addressId) => {
	try {
		const response =
			await serverInstance.get(`biop/subscriber/masters/get_address?address_id=${addressId}`,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
}

CommonServiceRoutines.fetchAddress = async(session, addressId) => {
	let addressDetails = session.getFromSessionCache(getBucketNames().COMPANY_ADDRESSES, addressId);
	if (addressDetails) return addressDetails;

	addressDetails = await CommonServiceRoutines.getAddress(session.getServerInstance('SUBSCRIBER'),
										session.getAccessToken(), addressId);

	session.setInSessionCache(getBucketNames().COMPANY_ADDRESSES, addressId, addressDetails);

	return addressDetails;
}

CommonServiceRoutines.getDefaultAddress = async(serverInstance, accessToken, orgId) => {
	try {
		const response =
			await serverInstance.get(`biop/subscriber/masters/get_default_address?org_id=${orgId}`,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
}

CommonServiceRoutines.fetchDefaultAddress = async(session, orgId) => {
	let addressDetails = session.getFromSessionCache(getBucketNames().COMPANY_DEFAULT_ADDRESSES, orgId);
	if (addressDetails) {
		//console.log("CACHED DATA");
		return addressDetails;
	}

	addressDetails = await CommonServiceRoutines.getDefaultAddress(session.getServerInstance('SUBSCRIBER'),
										session.getAccessToken(), orgId);

	session.setInSessionCache(getBucketNames().COMPANY_DEFAULT_ADDRESSES, orgId, addressDetails);
	session.setInSessionCache(getBucketNames().COMPANY_ADDRESSES, addressDetails.address_id, addressDetails);

	return addressDetails;
}

CommonServiceRoutines.addressList = async (serverInstance, accessToken, orgId, searchText) => {
	let params = {};
	if (orgId) {
		params.org_id = orgId;
	}
	if (searchText) {
		params.address_line = searchText;
	}
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/masters/addresslist`,
			{
				params,
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
}

CommonServiceRoutines.fetchAddressList = async(session, orgId) => {
	let list = session.getFromSessionCache(getBucketNames().COMPANIES_ADDRESS_LISTS, orgId);
	if (list) {
        //console.log(list);
		return list;
	}

	list = await CommonServiceRoutines.addressList(session.getServerInstance('SUBSCRIBER'),
										session.getAccessToken(), orgId);

	session.setInSessionCache(getBucketNames().COMPANIES_ADDRESS_LISTS, orgId, list);

    //console.log(list);

	return list;
}

CommonServiceRoutines.salesOrderList = async (serverInstance, accessToken, orderNumber, orderIssueDate, orgId) => {
	let response;
	let params = {};
	if (orderNumber) {
		params.order_number = orderNumber;
	}
	if (orderIssueDate) {
		params.order_issue_date = orderIssueDate;
	}
	if (orgId) {
		params.seller_org_id = orgId;
	}

	try {
		response = await serverInstance.get(
			`biop/subscriber/sales_order/list`,
			{
				params,
				headers: {
						"X-Auth": accessToken
				}
			}
		);

		return response.data;
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
}

CommonServiceRoutines.purchaseOrderList = async (serverInstance, accessToken, orderNumber, orderIssueDate, orgId) => {
	let response;
	let params = {};
	if (orderNumber) {
		params.order_number = orderNumber;
	}
	if (orderIssueDate) {
		params.order_issue_date = orderIssueDate;
	}
	if (orgId) {
		params.buyer_org_id = orgId;
	}

	try {
		response = await serverInstance.get(
			`biop/subscriber/order/list`,
			{
				params,
				headers: {
					"X-Auth": accessToken
				}
			}
		);

		return response.data;
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
}

CommonServiceRoutines.getFileDetails = async (serverInstance, accessToken, fileId) => {
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/file/fetch?file_id=${ fileId }`,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);

		return response.data;
	}
	catch(e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
}

CommonServiceRoutines.fetchFile = async (session, fileId) => {

	let fileDetails = await CommonServiceRoutines.getFileDetails(session.getServerInstance('SUBSCRIBER'),
										session.getAccessToken(), fileId);

	return fileDetails;
}

CommonServiceRoutines.markdown_to_html = async (session, markdown) => {
	const rocServer = session.getServerInstance('SUBSCRIBER');
	const accessToken = session.getAccessToken();

	let response;
	try {
		response = await rocServer.post(`biop/subscriber/utils/markdown_to_html`, markdown,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );
    
        return response.data;
	} catch (e) {
		console.log(e);
        return handleAxiosHTTPException(e);
	}
}


CommonServiceRoutines.fetchInvoice = async (session, invoiceNumber, invoiceDate, orgId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		invoice_number : invoiceNumber,
		invoice_date : invoiceDate,
		seller_org_id : orgId
	};
    const logged_in_company_id = session.getLoggedInCompanyId();
    try {
        if (logged_in_company_id === orgId) {
            response = await serverInstance.get(
                `biop/subscriber/invoice/fetch_using_number_and_date`,
                {
                    params,
                    headers: {
                        "X-Auth": accessToken,
                    },
                }
            );
        }
        else {
            response = await serverInstance.get(
                `biop/subscriber/received_invoice/fetch_using_number_and_date`,
                {
                    params,
                    headers: {
                        "X-Auth": accessToken,
                    },
                }
            );
        }
    } catch (e) {
		return handleAxiosHTTPException(e);
    }

    return {
        owned: logged_in_company_id === orgId,
        invoice: response.data
    };
}

CommonServiceRoutines.withdrawReferral = async (session, referralId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		referral_id : referralId,
	};
    try {
        response = await serverInstance.get(
            `biop/subscriber/referral_manager/fetch`,
            {
                params,
                headers: {
                    "X-Auth": accessToken,
                },
            }
        );
    } catch (e) {
		return handleAxiosHTTPException(e);
    }

	try {
		await serverInstance.post("biop/subscriber/referral_manager/withdraw", response.data, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}

    return;
}



export default CommonServiceRoutines;

