import { addToDate, today, valuePresent, addError } from 'WebUI_Framework'
import commonServiceRoutines from "./CommonServiceRoutines";
const formatAddress = (address) => {
	let strAddress = "";
	strAddress += (valuePresent(address.address_line_1)?address.address_line_1.trim():"") + ",";
	strAddress += (valuePresent(address.address_line_2)?  address.address_line_2.trim():"") + ",";
	strAddress += valuePresent(address.city)?  address.city.trim():"";
	if (strAddress === ",,") strAddress = "";
	strAddress = strAddress.replace(/[,]+/g, ", ");
	return strAddress;
}

const copyAddressElements = (i_address) => {
	let o_address = {};

	o_address.address_line_1 = i_address.address_line_1;
	o_address.address_line_2 = i_address.address_line_2;
	o_address.city = i_address.city;
	o_address.state = i_address.state;
	o_address.cntry = i_address.cntry;
	o_address.zip_code = i_address.zip_code;

	return o_address;
}

const getServiceDetails = (session) => {
	return { serverInstance: session.getServerInstance('SUBSCRIBER'), accessToken: session.getAccessToken() };
}

const getAAAServiceDetails = (session) => {
	return { serverInstance: session.getServerInstance('AAA'), accessToken: session.getAccessToken() };
}

const getROCServiceDetails = (session) => {
	return { serverInstance: session.getServerInstance('ROC'), accessToken: session.getAccessToken() };
}

const isSubscriber = (session) => {
	const loggedInCompanyId = session.getLoggedInCompanyId();
	const parentCompanyId = session.getLoggedInParentCompanyId();

	return (loggedInCompanyId === parentCompanyId);
}

const orderFromQuotation = (quotation, rfq) => {
	let seller;
	for (let i = 0; i < rfq.sellers.length; i++) {
		if (quotation.header.seller_org_id === rfq.sellers[i].seller_org_id) {
			seller = rfq.sellers[i];
			break;
		}
	}
	if (!valuePresent(seller)) {
		console.log(quotation);
		console.log(rfq);
		throw new Error("Could not locate the seller in the RFQ");
	}

	const order = {
		order_header : {

			rfq_number: rfq.header.rfq_number
			,rfq_issue_date: rfq.header.rfq_issue_date

			,quotation_number: quotation.header.quotation_number
			,quotation_issue_date: quotation.header.quotation_issue_date

			,description: rfq.header.description

			,order_contact_user_name: rfq.header.rfq_contact_user_name
			,order_contact_email_id: rfq.header.rfq_contact_email_id
			,order_contact_phone_num: rfq.header.rfq_contact_phone_num

			,buyer_org_id: quotation.header.buyer_org_id

			,seller_org_id: seller.seller_org_id
			,seller_org_name: seller.seller_org_name

			,seller_address_id: seller.seller_address_id
			,seller_address: seller.seller_address
			,seller_contact_user_name: seller.seller_contact_user_name
			,seller_contact_email_id: seller.seller_contact_email_id
			,seller_contact_phone_num: seller.seller_contact_phone_num

			,billing_address_id: rfq.header.billing_address_id
			,billing_address: rfq.header.billing_address
			,billing_contact_user_name: rfq.header.billing_contact_user_name
			,billing_contact_email_id: rfq.header.billing_contact_email_id
			,billing_contact_phone_num: rfq.header.billing_contact_phone_num

			,delivery_address_id: rfq.header.delivery_address_id
			,delivery_address: rfq.header.delivery_address
			,delivery_contact_user_name: rfq.header.delivery_contact_user_name
			,delivery_contact_email_id: rfq.header.delivery_contact_email_id
			,delivery_contact_phone_num: rfq.header.delivery_contact_phone_num

			,currency: rfq.header.currency

			,payment_period: quotation.header.payment_period
			,payment_period_unit: quotation.header.payment_period_unit
		},
		order_line_item : []
	};

	for (let i = 0; i < quotation.details.length; i++) {
		order.order_line_item[i] = {
			hsn_code: quotation.details[i].hsn_conde
			,seller_item_id: quotation.details[i].seller_item_id
			,seller_item_description: quotation.details[i].seller_item_description
			,quantity: quotation.details[i].quantity
			,unit_of_measure: quotation.details[i].unit_of_measure
			,rate_per_unit: quotation.details[i].rate_per_unit
			,item_delivery_from_date: quotation.details[i].item_delivery_from_date
			,item_delivery_to_date: quotation.details[i].item_delivery_to_date
		};
	}


	return order;
}

const invoiceFromDeliveryNote = async (session, delivery_note, sales_order) => {
	let invoice;
	if (valuePresent(sales_order)) {
		const order_header = sales_order.sales_order_header;
		const order_details = sales_order.order_line_item;
		const order_item_details = {};
		for (let i = 0; i < order_details.length; i++) {
			const details = order_details[i];
			if (!valuePresent(order_item_details[details.seller_item_id])) {
				order_item_details[details.seller_item_id] = [];
			}
			const j = order_item_details[details.seller_item_id].length;
			order_item_details[details.seller_item_id][j] = details;
		}
		let c_buyer_address_id;
		let c_buyer_address;
		let c_buyer_contact_user_name;
		let c_buyer_contact_email_id;
		let c_buyer_contact_phone_num;
		let c_currency;

		if (valuePresent(delivery_note.header.buyer_address_id)) {
			c_buyer_address_id = delivery_note.header.buyer_address_id;
		}
		else { c_buyer_address_id = order_header.billing_address_id; }

		if (valuePresent(delivery_note.header.buyer_address)) {
			c_buyer_address = delivery_note.header.buyer_address;
		}
		else { c_buyer_address = order_header.billing_address; }

		if (valuePresent(delivery_note.header.buyer_contact_user_name)) {
			c_buyer_contact_user_name = delivery_note.header.buyer_contact_user_name;
		}
		else { c_buyer_contact_user_name = order_header.billing_contact_user_name; }

		if (valuePresent(delivery_note.header.buyer_contact_email_id)) {
			c_buyer_contact_email_id = delivery_note.header.buyer_contact_email_id;
		}
		else { c_buyer_contact_email_id = order_header.billing_contact_email_id; }

		if (valuePresent(delivery_note.header.buyer_contact_phone_num)) {
			c_buyer_contact_phone_num = delivery_note.header.buyer_contact_phone_num;
		}
		else { c_buyer_contact_phone_num = order_header.billing_contact_phone_num; }

		if (valuePresent(delivery_note.header.currency)) {
			c_currency = delivery_note.header.currency;
		}
		else { c_currency = order_header.currency; }

		invoice = {
			header : {
				order_number: order_header.order_number
				,order_issue_date: order_header.order_issue_date

				,delivery_note_number: delivery_note.header.delivery_note_number
				,delivery_note_date: delivery_note.header.delivery_note_date

				,receipt_note_number: delivery_note.header.receipt_note_number
				,receipt_note_date: delivery_note.header.receipt_note_date

				,seller_org_id: delivery_note.header.seller_org_id
				,seller_org_name: delivery_note.header.seller_org_name
				,seller_address_id: order_header.seller_address_id
				,seller_address: order_header.seller_address
				,seller_contact_user_name: order_header.seller_contact_user_name
				,seller_contact_email_id: order_header.seller_contact_email_id
				,seller_contact_phone_num: order_header.seller_contact_phone_num

				,buyer_org_id: delivery_note.header.buyer_org_id
				,buyer_org_name: delivery_note.header.buyer_org_name
				,buyer_address_id: c_buyer_address_id
				,buyer_address: c_buyer_address
				,buyer_contact_user_name: c_buyer_contact_user_name
				,buyer_contact_email_id: c_buyer_contact_email_id
				,buyer_contact_phone_num: c_buyer_contact_phone_num

				,currency : c_currency

			},
			details : []
		};

		/*
		if (valuePresent(delivery_note.header.description)) {
			invoice.header.description = delivery_note.header.description;
		}
		*/
		if (valuePresent(order_header.payment_period)) {
			let payment_date = addToDate(today(session),
										order_header.payment_period,
										order_header.payment_period_unit);
			invoice.header.payment_due_date = payment_date;
		}
		if (valuePresent(delivery_note.header.acct_identifier)) {
			invoice.header.acct_identifier = delivery_note.header.acct_identifier;
		}
		for (let i = 0; i < delivery_note.details.length; i++) {
			const details = delivery_note.details[i];
			let c_rate_per_unit;
			let c_currency;
			let c_hsn_code;
			let c_seller_item_description;
			if (valuePresent(details.rate_per_unit)) {
				c_rate_per_unit = details.rate_per_unit;
				c_currency = details.currency;
				c_hsn_code = details.hsn_code;
				c_seller_item_description = details.seller_item_description;
			}
			else {
				if (order_item_details[details.seller_item_id].length === 1) {
					c_rate_per_unit = order_item_details[details.seller_item_id][0].rate_per_unit;
					c_currency = order_item_details[details.seller_item_id][0].currency;
					c_hsn_code = order_item_details[details.seller_item_id][0].hsn_code;
					c_seller_item_description = order_item_details[details.seller_item_id][0].seller_item_description;
				}
			}
			invoice.details[i] = {
				seller_item_id : details.seller_item_id
				,unit_of_measure : details.unit_of_measure
				,quantity : details.accepted_quantity
			};
			if (valuePresent(c_rate_per_unit))
				invoice.details[i].rate_per_unit = c_rate_per_unit;
			if (valuePresent(c_currency))
				invoice.details[i].currency = c_currency;
			if (valuePresent(c_hsn_code))
				invoice.details[i].hsn_code = c_hsn_code;
			if (valuePresent(c_seller_item_description))
				invoice.details[i].seller_item_description = c_seller_item_description;

			if (valuePresent(details.tax_details)) {
				invoice.details[i].tax_details = [];
				for (let j = 0; j < details.tax_details.length; j++) {
					const tax_line = {};
					tax_line.tax_type = details.tax_details[j].tax_type;
					tax_line.tax_percentage = details.tax_details[j].tax_percentage;
					invoice.details[i].tax_details[j] = tax_line;
				}
			}
		}
	}
	else {
		const sellerDetails = await commonServiceRoutines.fetchCompany(session, delivery_note.header.seller_org_id);
		const sellerAddress = await commonServiceRoutines.fetchDefaultAddress(session, delivery_note.header.seller_org_id);
		invoice = {
			header : {
				delivery_note_number: delivery_note.header.delivery_note_number
				,delivery_note_date: delivery_note.header.delivery_note_date

				,receipt_note_number: delivery_note.header.receipt_note_number
				,receipt_note_date: delivery_note.header.receipt_note_date

				,order_number: delivery_note.header.order_number
				,order_issue_date: delivery_note.header.order_issue_date

				,seller_org_name: sellerDetails.seller_org_name
				,seller_contact_user_name: sellerDetails.contact_name
				,seller_contact_email_id: sellerDetails.contact_email_id
				,seller_contact_phone_num: sellerDetails.contact_phone_number
				,seller_address_id: sellerAddress.address_id
				,seller_address: sellerAddress

				,buyer_org_id: delivery_note.header.buyer_org_id
				,buyer_org_name: delivery_note.header.buyer_org_name
			},
			details : []
		};

		const buyerDetails = await commonServiceRoutines.fetchCompany(session, delivery_note.header.buyer_org_id);
		const buyerAddress = await commonServiceRoutines.fetchDefaultAddress(session, delivery_note.header.buyer_org_id);
		if (valuePresent(delivery_note.header.buyer_address_id)) {
			invoice.header.buyer_address_id = delivery_note.header.buyer_address_id;
		}
		else {
			invoice.header.buyer_address_id = buyerDetails.address_id;
		}
		if (valuePresent(delivery_note.header.buyer_address)) {
			invoice.header.buyer_address = delivery_note.header.buyer_address;
		}
		else {
			invoice.header.buyer_address = buyerAddress;
		}
		if (valuePresent(delivery_note.header.buyer_contact_user_name)) {
			invoice.header.buyer_contact_user_name = delivery_note.header.buyer_contact_user_name;
		}
		else {
			invoice.header.buyer_contact_user_name = buyerDetails.contact_name;
		}
		if (valuePresent(delivery_note.header.buyer_contact_email_id)) {
			invoice.header.buyer_contact_email_id = delivery_note.header.buyer_contact_email_id;
		}
		else {
			invoice.header.buyer_contact_email_id  = buyerDetails.contact_email_id;
		}
		if (valuePresent(delivery_note.header.buyer_contact_phone_num)) {
			invoice.header.buyer_contact_phone_num = delivery_note.header.buyer_contact_phone_num;
		}
		else {
			invoice.header.buyer_contact_phone_num = buyerDetails.contact_phone_number;
		}

		for (let i = 0; i < delivery_note.details.length; i++) {
			const details = delivery_note.details[i];
			invoice.details[i] = {
				seller_item_id : details.seller_item_id
				,unit_of_measure : details.unit_of_measure
				,quantity : details.accepted_quantity
				,rate_per_unit : details.rate_per_unit
				,currency : details.currency
			};

			if (valuePresent(details.hsn_code))
				invoice.details[i].hsn_code = details.hsn_code;
			if (valuePresent(details.seller_item_description))
				invoice.details[i].seller_item_description = details.seller_item_description;

			if (valuePresent(details.tax_details)) {
				invoice.details[i].tax_details = [];
				for (let j = 0; j < details.tax_details.length; j++) {
					const tax_line = {};
					tax_line.tax_type = details.tax_details[j].tax_type;
					tax_line.tax_percentage = details.tax_details[j].tax_percentage;
					invoice.details[i].tax_details[j] = tax_line;
				}
			}
		}
	}

	return invoice;

}

const setReferralErrors = (excp_obj) => {
    let r;
    for (const err of excp_obj.err) {
        r = addError(err.description, undefined, r)
    }

    console.log(r);

    return r;
}

export { formatAddress, getServiceDetails, getAAAServiceDetails,
	getROCServiceDetails, isSubscriber, copyAddressElements,
	orderFromQuotation, invoiceFromDeliveryNote, setReferralErrors
};

