import React from 'react';
import {
    GenericComponent,
    NarrowButton,
    GenericForm,
    FormField,
    valuePresent,

    fieldValidations,
    getInitialState,
    copyFormFieldsToDataFields,
    copyDataFieldsToFormFields,
    deepCopy,
    raiseError,

    MultiRecFormField,
    MultiRecHandler,
    MultiRec,
    amount_textbox,
    amounttextbox,
    filtered_select,
    filteredselect,
    currency,
    Currency,
    generic_checkbox,
    genericcheckbox,
    generic_string,
    genericstring,
    org_name,
    orgname,

} from 'WebUI_Framework';

import partnerServiceRoutines from "./PartnerServiceRoutines";
import subscriberServiceRoutines from "../subscribers/SubscriberServiceRoutines";

const fields = {
	parent_org_id: {
		id: "parent_org_id", name: "parent_org_id", label: window.getLabelLiteralWithColon("PARENT_COMPANY_NAME"),
		class: orgname, component: org_name, mandatory: true
	},

	org_id: {
		id: "org_id", name: "org_id", label: window.getLabelLiteralWithColon("COMPANY_NAME"),
		class: orgname, component: org_name, mandatory: true
	},

	org_name: {
		id: "org_name", name: "org_name", label: window.getLabelLiteralWithColon("COMPANY_NAME"),
		class: orgname, component: org_name, mandatory: true
	},

	parent_org_name: {
		id: "parent_org_name", name: "parent_org_name", label: window.getLabelLiteralWithColon("PARENT_COMPANY_NAME"),
		class: orgname, component: org_name, mandatory: true
	},

	category: {
		id: "category", name: "category", label: window.getLabelLiteralWithColon("PARTNER_CATEGORY"),
		class: filteredselect, component: filtered_select, mandatory: false
		//class: genericstring, component: generic_string, mandatory: true
	},

	commmercial_tax_applicable: {
		id: "commmercial_tax_applicable", name: "commmercial_tax_applicable",
        label: window.getLabelLiteralWithColon("COMMMERCIAL_TAX_APPLICABLE"),
		class: genericcheckbox, component: generic_checkbox, mandatory: false
		//class: genericstring, component: generic_string, mandatory: true
	},

	max_invoice_amount: {
		id: "max_invoice_amount", name: "max_invoice_amount", label: window.getLabelLiteralWithColon("MAX_INVOICE_AMOUNT"),
		class: amounttextbox, component: amount_textbox, mandatory: false
		//class: genericstring, component: generic_string, mandatory: true
	},

	currency: {
		id: "currency", name: "currency", label: window.getLabelLiteralWithColon("CURRENCY"),
		class: Currency, component: currency, mandatory: false
		//class: genericstring, component: generic_string, mandatory: true
	},
}

let mapping = [
	["org_id", "org_id"],
	["org_name", "org_name"],
	["parent_org_id", "parent_org_id"],
	["category", "category"],
	["max_invoice_amount", "max_invoice_amount"],
	["currency", "currency"],
	["commmercial_tax_applicable", "commmercial_tax_applicable"],
];

const SKU_MAPPING_MREC = "SKU_MAPPING_MREC"

const sku_mapping_fields = {
    company_item_id: {
        id: "company_item_id", name: "company_item_id", label: window.getLabelLiteralWithColon("COMPANY_NAME"),
        class: genericstring, component: generic_string, mandatory: true
    },
    partner_item_id: {
        id: "partner_item_id", name: "partner_item_id", label: window.getLabelLiteralWithColon("COMPANY_NAME"),
        class: genericstring, component: generic_string, mandatory: true
    }
};

const sku_mapping_mapping = [
    ["company_item_id", "company_item_id"],
    ["partner_item_id", "partner_item_id"],
];

class EditPartnerParameters extends GenericComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;
        this.state.step = 1;
        this.state.editCount = 0;

        this.readOnly = false;
 
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();

        this.action = props.action;
        this.partner_parameters = {};

        this.logged_in_company_id = this.session.getLoggedInCompanyId();
        this.parent_org_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_company_id === this.parent_org_id);
        this.org_id = props.orgId;
        this.valid_partner_categories = [];
        this.subscriber_parameters = {};

        this.page_title = "";
        this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
        if (this.action === "EDIT") {
        }
        else if (this.action === "VIEW") {
            this.readOnly = true;
        }
        this.page_title += " " + window.getLabelLiteral("PARTNER_PARAMETER_SETUP_TITLE");

        this.skuMappingHandler = new MultiRecHandler(this.session, sku_mapping_fields, sku_mapping_mapping, SKU_MAPPING_MREC);
        this.sku_mapping_buttons = [
            {
                literal: "Add",
                func: () => this.addSkuMapping()
            }
        ]
    }

    initialState = getInitialState(fields);

    state = {
        data: deepCopy(this.initialState),
    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                selectData: this.partner_parameters, error
            });
        else {
            this.setState({ loading: false });
        }
    }

    fetch = async (org_id) => {
        let i = 0;
        this.subscriber_parameters = await subscriberServiceRoutines.fetchParameters(this.session, this.parent_org_id);
        for (let element of this.subscriber_parameters.valid_partner_categories) {
            this.valid_partner_categories[i++] = { name : element.name, desc: element.name }
        }
        return await partnerServiceRoutines.fetchParameters(this.session, org_id);
    }

    initializations = (data) => {
        if (!valuePresent(this.partner_parameters.sku_mapping))
            this.partner_parameters.sku_mapping = (this.action === 'EDIT')?[{}]:[];

        copyDataFieldsToFormFields(mapping, this.partner_parameters, data);
        data = this.skuMappingHandler.dataArrayToFormFields(this.partner_parameters.sku_mapping, data);
        data.parent_org_name = this.session.getLoggedInParentCompanyName();

        return data;
    }

    componentDidMount = async () => {
        let newData;
        try {
            switch (this.action) {
                /*eslint no-fallthrough: 0*/
                case 'VIEW':
                case 'EDIT':
                    newData = (this.state.data);
                    this.partner_parameters = await this.fetch(this.org_id);

                    newData = this.initializations(newData);

                    this.setState({ loading: false, data: newData });

                    break;
                default:
                    this.setError(raiseError("EditPartnerParameters: Invalid action [" + this.action + "]"));
                    this.backHandler(this.action, this.error);
                    break;
            }
        }
        catch (e) {
            console.log(this.action);
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            this.backHandler(this.action, this.error);
        }
    }

    addSkuMapping = () => {
        let data = this.state.data;
        this.resetError();
        let [newData, new_list] = this.skuMappingHandler.addMultiRecRow(data, this.partner_parameters.sku_mapping, {});
        this.partner_parameters.sku_mapping = new_list;
        this.setState({ data: newData });
    }

    deleteSkuMapping = (data, i) => {
        this.resetError();
        let [newData, updated_list] =
            this.skuMappingHandler.deleteMultiRecRow(this.state.data, this.partner_parameters.sku_mapping, i);
        this.partner_parameters.sku_mapping = updated_list;
        this.setState({ data: newData });
    }

    getSkuMappingHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key} width={"40%"}>{window.getLabelLiteral("COMPANY_ITEM_ID")}</th>
                <th key={++key} width={"40%"}>{window.getLabelLiteral("PARTNER_ITEM_ID")}</th>
                {!this.readOnly &&
                <th key={++key} width={"20%"}>{window.getLabelLiteral("ACTIONS")}</th>
                }
            </tr>
        );
    }

    getSkuMappingRow = (rowData, index) => {
        const i = index - 1;
        let key = 0;
        let style = { borderBottom: "0px solid" };

        let r = <React.Fragment key={index}>
            <tr key={++key}>
                <td key={++key} width={"40%"} style={style}>
                    <MultiRecFormField
                        field={sku_mapping_fields.company_item_id}
                        controlProps={this.controlProps}
                        index={i}
                        multiRecId={SKU_MAPPING_MREC}
                        readOnly={this.readOnly}
                        style={{ width: "100%" }}
                    />
                </td>
                <td key={++key} width={"40%"} style={style}>
                    <MultiRecFormField
                        field={sku_mapping_fields.partner_item_id}
                        controlProps={this.controlProps}
                        index={i}
                        multiRecId={SKU_MAPPING_MREC}
                        readOnly={this.readOnly}
                        style={{ width: "100%" }}
                    />
                </td>
                {!this.readOnly &&
                <td key={++key} width={"20%"} style={style}>
                    <div style={{ paddingLeft: "20px", cursor: "pointer" }}>
                        <span onClick={() => { return this.deleteSkuMapping(rowData, i); }}
                            data-toggle="tooltip" title={window.getLabelLiteral("DELETE")}
                        >
                            <i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
                        </span>
                    </div>
                </td>
                }
            </tr>
        </React.Fragment>
        return r;
    }

    onReturn = (ret) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        this.resetError();
        if (ret.error) {
            this.setError(ret.error);
            return;
        }

        /*eslint no-fallthrough: 0*/
        switch (ret.retFrom) {
            case "EDIT":
            case "VIEW":
                this.setState((prevState, props) => {
                    this.partner_parameters = ret.selectData;
                    prevState.data = this.initializations(prevState.data);
                    prevState.editCount++;
                    return deepCopy(prevState);
                });
                break;
            case 'BACK':
                break;
            default:
                this.setError(raiseError("Invalid return from EditPartnerParameters"));
        }
    }

    handler = (action) => {
        this.callForm(EditPartnerParameters, { ...this.props, action }, this.onReturn);
    }

    data_render = () => {
        let key = 0;

        let datablk = (
            <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="container-xxl row border border-0 p-0 m-0 ">
                        <div className="col-4 m-0 p-0 g-0">
                            <span className="h6 text-start">
                                {(this.action === "VIEW")
                                ?
                                    this.getBreadcrumb([window.getLabelLiteral("VIEW_PARTNER_PARAMETERS")])
                                :
                                    this.getBreadcrumb([window.getLabelLiteral("EDIT_PARTNER_PARAMETERS")])
                                }
                            </span>
                        </div>
                        <div className="d-inline-flex col-7 justify-content-end">
                            <span className="h6 text-center">
                                {(this.action === "VIEW")&&
                                    <button type="button"
                                        style={{
                                            color: "blue", textDecoration: "underline", cursor: "pointer",
                                            border: "none", background: "none", padding: 0, margin:0,
                                            textAlign: "left"
                                        }}
                                        onClick={() => this.handler("EDIT")}
                                    >
                                        {window.getLabelLiteral("EDIT_PARAMETERS")}
                                    </button>
                                }
                            </span>
                        </div>
                        <div className="col-1 ">
                            &nbsp;&nbsp;
                        </div>
                    </div>
                    <hr className="mb-4"/>
                    <div className="col-sm-6" >
                        <FormField field={fields.org_name} controlProps={this.controlProps} readOnly={true} />
                    </div>
                    <div className="col-sm-6" >
                        <FormField field={fields.parent_org_name} controlProps={this.controlProps} readOnly={true} />
                    </div>
                    <div className="col-sm-6" >
                        <FormField
                            field={fields.currency}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6" >
                        <FormField
                            field={fields.max_invoice_amount}
                            currency={this.state.data.currency}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                            style={{textAlign: "left"}}
                        />
                    </div>
                    <div className="col-sm-6" >
                        <FormField
                            field={fields.category}
                            controlProps={this.controlProps}
                            list={this.valid_partner_categories}
                            keyName={"name"}
                            descName={"desc"}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6" >
                        <FormField
                            field={fields.commmercial_tax_applicable}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    {(!this.readOnly || (valuePresent(this.partner_parameters?.sku_mapping?.length) &&
                        this.partner_parameters?.sku_mapping.length >0)) &&
                    <div className="col-sm-12 mt-2" >
                        <hr className="mt-5 mb-4"/>
                        <div className="col-sm-6">
                            <MultiRec
                                noInfoMessage={""}
                                pageHeading={window.getLabelLiteral("SKU_MAPPING")}
                                pageHeadingClass={"fs-6 fw-bold"}
                                data={this.partner_parameters.sku_mapping}
                                getRow={this.getSkuMappingRow}
                                getHeader={this.getSkuMappingHeader}
                                buttons={(this.readOnly) ? null : this.sku_mapping_buttons}
                            />
                            {(!valuePresent(this.partner_parameters?.sku_mapping?.length) ||
                                this.partner_parameters?.sku_mapping.length ===0) &&
                                <span>
                                {"NO DATA"}
                                </span>
                            }
                        </div>
                    </div>
                    }
                    {!this.readOnly &&<hr className="mt-5 mb-4"/>}
                    <div className="row  p-0 m-0 g-0 " >
                        <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                            {(this.action !== 'VIEW') &&
                                <>
                                    <NarrowButton
                                        value="Submit" onClick={this.submitForm} key={(++key).toString()} />
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    loading_render = () => {
        let datablk = <div className="container border border-0 pt-0 m-0 ">
            <div className="row  p-0 m-0 g-0 " >
                <div className="col-sm-12 h4 text-center" >{this.page_title}</div>
                <div className="d-flex justify-content-evenly">
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            </div>
        </div>

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    render = () => {
        if (this.state.loading) {
            return this.loading_render();
        }
        else {
            return this.data_render();
        }
    }

    validateForm = () => {
        let r = fieldValidations(fields, this.state.data);
        r = this.skuMappingHandler.multiRecFieldValidations(this.state.data, null, r);
        if (!r.status) {
            console.log(r);
            this.setState({ error: r });
            return false;
        }

        return true;
    }

    submitForm = async () => {
        this.resetError();
        if (!this.validateForm()) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            return;
        }

        let partner_parameters = deepCopy(this.partner_parameters);
        let data = (this.state.data);
        copyFormFieldsToDataFields(mapping, data, partner_parameters, fields);
        this.skuMappingHandler.formFieldsToDataArray(this.state.data, partner_parameters.sku_mapping);

        if (partner_parameters.sku_mapping.length === 0) {
            const { sku_mapping, ...rest } = partner_parameters;
            partner_parameters = rest;
        }

        try {
            await partnerServiceRoutines.modifyParameters(this.session, partner_parameters);
            partner_parameters = await this.fetch(this.org_id);
            this.partner_parameters = partner_parameters;
            this.backHandler(this.action);
        }
        catch (e) {
            console.log(this.partner_parameters);
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
    }

}


export default EditPartnerParameters;
