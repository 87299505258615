import { main, valuePresent } from 'WebUI_Framework';
import ManagePartners from './partners/ManagePartners';
import ManageUsers from './users/ManageUsers';
import ManageOrders from './orders/ManageOrders';
import ManageQuotationRequests from './rfq/ManageQuotationRequests';
import ManageQuotations from './quotations/ManageQuotations';
import ManageBankAccounts from './bankaccounts/ManageBankAccounts';
import ManageIssuedInvoices from './invoice/ManageIssuedInvoices';
import ManageReceivedInvoices from './received_invoices/ManageReceivedInvoices';
import ManageSalesOrders from './sales_orders/ManageSalesOrders';
import ManageCommercialTax from './commercialtax/ManageCommercialTax';
import ManageAddress from './address/ManageAddress';
import ManageRuleSetup from './rules/ManageRuleSetup';
import ManageSubscriber from './subscribers/ManageSubscriber';
import SwitchOrg from './common/SwitchOrg';
import ManageReceiptNotes from './receipt_note/ManageReceiptNotes';
import ManageDeliveryNotes from './delivery_note/ManageDeliveryNotes';
import ManageDeliveryChallans from './delivery_challan/ManageDeliveryChallans';
import ManagePartnershipRequests from './partnership_requests/ManagePartnershipRequests';
import ManageReceivedQuotations from './received_quotations/ManageReceivedQuotations';
import ManageDebitCreditNotes from './debit_credit_notes/ManageDebitCreditNotes';
import EditSubscriberParameters from './subscribers/EditSubscriberParameters';
import ManageReferrals from './referral/ManageReferrals';
import EditCompany from './company/EditCompany';
//import ViewSubscriberParameters from './subscribers/ViewSubscriberParameters';

import { appendToUsecases, addToHeaderComponent } from 'WebUI_Framework';


appendToUsecases({ManagePartners : ManagePartners });
appendToUsecases({ManageUsers : ManageUsers });
appendToUsecases({ManageOrders : ManageOrders });
appendToUsecases({ManageBankAccounts : ManageBankAccounts });
appendToUsecases({ManageIssuedInvoices : ManageIssuedInvoices });
appendToUsecases({ManageReceivedInvoices: ManageReceivedInvoices });
appendToUsecases({ManageSalesOrders: ManageSalesOrders });
appendToUsecases({ManageCommercialTax : ManageCommercialTax });
appendToUsecases({ManageSubscriber : ManageSubscriber});
appendToUsecases({ManageAddress: ManageAddress});
appendToUsecases({ManageRuleSetup: ManageRuleSetup});
appendToUsecases({ManageReceiptNotes : ManageReceiptNotes});
appendToUsecases({ManageDeliveryNotes : ManageDeliveryNotes});
appendToUsecases({ManageDeliveryChallans : ManageDeliveryChallans});
appendToUsecases({ManagePartnershipRequests : ManagePartnershipRequests});
appendToUsecases({ManageQuotationRequests : ManageQuotationRequests});
appendToUsecases({ManageQuotations : ManageQuotations});
appendToUsecases({ManageReceivedQuotations : ManageReceivedQuotations});
appendToUsecases({ManageDebitCreditNotes : ManageDebitCreditNotes});
appendToUsecases({EditSubscriberParameters : EditSubscriberParameters});
appendToUsecases({ManageReferrals : ManageReferrals});
appendToUsecases({EditCompany : EditCompany});
//appendToUsecases({ViewSubscriberParameters : ViewSubscriberParameters});
addToHeaderComponent(SwitchOrg)

let menuTreeToUse = (session) => {
	let access_type = session.getAccessType();
	if (access_type === 'S') {
		return access_type;
	}
	else if (access_type === 'B') {
        const user_profile = session.getUserProfile();
        if (valuePresent(user_profile.menu_tree)) return user_profile.menu_tree;

		let uca = session.getUserCompanyProps(session.getLoggedInCompanyId(), session.getLoggedInParentCompanyId());
		return (uca.org_is_subscriber)? "SB" : "NSB";
	}
	else {
		throw new Error("Invalid access type : "+access_type);
	}
}


main({app: 'subscriber', app_name: "Subscriber", base_name:"/subscriber", menuTreeToUse});
