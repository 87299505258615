import React from 'react';
import {
    GenericComponent,
    NarrowButton,
    GenericForm,
    FormField,

    MultiRecFormField,
    MultiRecHandler,
    file_set,
    FileSet,

    getInitialState,
    copyDataFieldsToFormFields,
    deepCopy,
    raiseError,

    documentnumber,
    document_number,
    inputdate,
    input_date,
    user_name,
    username,
    emailid,
    email_id,
    contactphonenumber,
    contact_phone_number,
    generic_string,
    genericstring,
    generic_integer,
    genericinteger,
    genericcheckbox,
    generic_checkbox,
    currency,
    Currency,
    item_id,
    itemid,
    genericdecimal,
    generic_decimal,
    unit_measure,
    unitmeasure,
    MultiRec,

} from 'WebUI_Framework';

import './ViewReceivedRFQ.css';

import quotationServiceRoutines from "./quotationServiceRoutines";
import Attachments from '../common/Attachments'
import { formatAddress } from '../common/utils'

const LINE_ITEMS_MREC = "LINE_ITEMS";

const header_fields = {
    buyer_org_name: {
        id: "buyer_org_name", name: "buyer_org_name", label: "Buyer Name:",
        class: genericstring, component: generic_string, mandatory: true
    },

    rfq_number: {
        id: "rfq_number", name: "rfq_number", label: "RFQ Number:",
        class: documentnumber, component: document_number, mandatory: true
    },

    rfq_issue_date: {
        id: "rfq_issue_date", name: "rfq_issue_date",
        label: "RFQ Issue Date:", class: inputdate, component: input_date, mandatory: true
    },

    rfq_contact_user_name: {
        id: "rfq_contact_user_name", name: "rfq_contact_user_name",
        label: "Contact Person:", class: username, component: user_name, mandatory: true
    },

    rfq_contact_email_id: {
        id: "rfq_contact_email_id", name: "rfq_contact_email_id",
        label: "Contact Email:", class: emailid, component: email_id, mandatory: true
    },

    rfq_contact_phone_num: {
        id: "rfq_contact_phone_num", name: "rfq_contact_phone_num",
        label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: true
    },

    rfq_valid_from_date: {
        id: "rfq_valid_from_date", name: "rfq_valid_from_date",
        label: "RFQ Valid From:", class: inputdate, component: input_date, mandatory: true
    },

    rfq_valid_to_date: {
        id: "rfq_valid_to_date", name: "rfq_valid_to_date",
        label: "RFQ Valid Till:", class: inputdate, component: input_date, mandatory: true
    },

    currency: {
        id: "currency", name: "currency", label: "Currency:",
        class: Currency, component: currency, mandatory: true
    },

    description: {
        id: "description", name: "description", label: "Description:",
        class: genericstring, component: generic_string, mandatory: true
    },

    notes: {
        id: "notes", name: "notes", label: "Notes:",
        class: genericstring, component: generic_string, mandatory: false
    },

    billing_address_id: {
        id: "billing_address_id", name: "billing_address_id", label: "Billing Address Id:",
        class: genericstring, component: generic_string, mandatory: false
    },

    billing_address: {
        id: "billing_address", name: "billing_address",
        label: "Address:", class: genericstring, component: generic_string, mandatory: true
    },

    billing_contact_user_name: {
        id: "billing_contact_user_name", name: "billing_contact_user_name",
        label: "Contact Person:", class: username, component: user_name, mandatory: false
    },

    billing_contact_email_id: {
        id: "billing_contact_email_id", name: "billing_contact_email_id",
        label: "Contact Email:", class: emailid, component: email_id, mandatory: false
    },

    billing_contact_phone_num: {
        id: "billing_contact_phone_num", name: "billing_contact_phone_num",
        label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
    },

    delivery_address_id: {
        id: "delivery_address_id", name: "delivery_address_id", label: "Delivery Address Id:",
        class: genericstring, component: generic_string, mandatory: false
    },

    delivery_address: {
        id: "delivery_address", name: "delivery_address",
        label: "Address:", class: genericstring, component: generic_string, mandatory: true
    },

    delivery_contact_user_name: {
        id: "delivery_contact_user_name", name: "delivery_contact_user_name",
        label: "Contact Person:", class: username, component: user_name, mandatory: false
    },

    delivery_contact_email_id: {
        id: "delivery_contact_email_id", name: "delivery_contact_email_id",
        label: "Contact Email:", class: emailid, component: email_id, mandatory: false
    },

    delivery_contact_phone_num: {
        id: "delivery_contact_phone_num", name: "delivery_contact_phone_num",
        label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
    },

    attachments: {
        id: "attachments", name: "attachments", label: window.getLabelLiteralWithColon("ATTACHMENTS"),
        class: file_set, component: FileSet, mandatory: false,
    },

    seller_org_id: {
        id: "seller_org_id", name: "seller_org_id", label: "Seller Id:",
        class: genericstring, component: generic_string, mandatory: false
    },

    seller_org_name: {
        id: "seller_org_name", name: "seller_org_name", label: "Company Name:",
        class: genericstring, component: generic_string, mandatory: true
    },

    seller_address_id: {
        id: "seller_address_id", name: "seller_address_id", label: "Seller Address Id:",
        class: genericstring, component: generic_string, mandatory: false
    },

    seller_address: {
        id: "seller_address", name: "seller_address",
        label: "Address:", class: genericstring, component: generic_string, mandatory: true,
        initialValue : {}
    },

    seller_contact_user_name: {
        id: "seller_contact_user_name", name: "seller_contact_user_name", label: "Contact Person:",
        class: username, component: user_name, mandatory: true
    },

    seller_contact_email_id: {
        id: "seller_contact_email_id", name: "seller_contact_email_id", label: "Contact Email:",
        class: emailid, component: email_id, mandatory: true
    },

    seller_contact_phone_num: {
        id: "seller_contact_phone_num", name: "seller_contact_phone_num", label: "Contact Phone:",
        class: contactphonenumber, component: contact_phone_number, mandatory: true
    },

}

let header_mapping = [
    ["rfq_number", "rfq_number"]
    ,["rfq_issue_date", "rfq_issue_date"]
    ,["rfq_contact_user_name", "rfq_contact_user_name"]
    ,["rfq_contact_email_id", "rfq_contact_email_id"]
    ,["rfq_contact_phone_num", "rfq_contact_phone_num"]
    ,["rfq_valid_from_date", "rfq_valid_from_date"]
    ,["rfq_valid_to_date", "rfq_valid_to_date"]
    ,["buyer_org_id", "buyer_org_id"]
    ,["buyer_org_name", "buyer_org_name"]
    ,["delivery_address_id", "delivery_address_id"]
    ,["delivery_contact_user_name", "delivery_contact_user_name"]
    ,["delivery_contact_email_id", "delivery_contact_email_id"]
    ,["delivery_contact_phone_num", "delivery_contact_phone_num"]
    ,["description", "description"]
    ,["notes", "notes"]
    ,["billing_address_id", "billing_address_id"]
    ,["billing_contact_user_name", "billing_contact_user_name"]
    ,["billing_contact_email_id", "billing_contact_email_id"]
    ,["billing_contact_phone_num", "billing_contact_phone_num"]
    ,["payment_period", "payment_period"]
    ,["payment_period_unit", "payment_period_unit"]
    ,["currency", "currency"]
    ,["attachments", "attachments"]
    ,["seller_org_id", "seller_org_id"]
    ,["seller_org_name", "seller_org_name"]
    ,["seller_address_id", "seller_address_id"]
    ,["seller_contact_user_name", "seller_contact_user_name"]
    ,["seller_contact_email_id", "seller_contact_email_id"]
    ,["seller_contact_phone_num", "seller_contact_phone_num"]
    ,["seller_address", "seller_address"]
];

const details_fields = {
    seller_item_id: {
        id: "seller_item_id", name: "seller_item_id", label: "Item Id:",
        class: itemid, component: item_id, mandatory: true
    },

    seller_item_description: {
        id: "seller_item_description", name: "seller_item_description", label: "Description:",
        class: genericstring, component: generic_string, mandatory: true
    },

    requested_quantity: {
        id: "requested_quantity", name: "requested_quantity", label: "requested_quantity:",
        class: genericdecimal, component: generic_decimal, mandatory: true
    },

    unit_of_measure: {
        id: "unit_of_measure", name: "unit_of_measure", label: "Unit:",
        class: unitmeasure, component: unit_measure, mandatory: true
    },

    item_delivery_from_date: {
        id: "item_delivery_from_date", name: "item_delivery_from_date", label: "Delivery From Date:",
        class: inputdate, component: input_date, mandatory: true
    },

    item_delivery_to_date: {
        id: "item_delivery_to_date", name: "item_delivery_to_date", label: "Delivery To Date:",
        class: inputdate, component: input_date, mandatory: true
    },

    notes: {
        id: "notes", name: "notes", label: "Notes:",
        class: genericstring, component: generic_string, mandatory: false
    },

    rfq_serial_id: {
        id: "rfq_serial_id", name: "rfq_serial_id", label: "RFQ Serial Id",
        class: genericinteger, component: generic_integer, mandatory: false,
    },
    
    deleted: {
        id: "deleted", name: "deleted", label: window.getLabelLiteralWithColon("DELETED"),
        class: genericcheckbox, component: generic_checkbox, mandatory: false
    },

}

let details_mapping = [
    ["seller_item_id", "seller_item_id"]
    ,["seller_item_description", "seller_item_description"]
    ,["requested_quantity", "requested_quantity"]
    ,["unit_of_measure", "unit_of_measure"]
    ,["notes", "notes"]
    ,["item_delivery_from_date", "item_delivery_from_date"]
    ,["item_delivery_to_date", "item_delivery_to_date"]
    ,["rfq_serial_id", "rfq_serial_id"]
    ,["deleted", "deleted"]
];

class ViewReceivedRFQ extends GenericComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;
        this.state.step = 1;

        this.readOnly = false;
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();
        this.action = "VIEW";
        this.rrfqId = props.rrfqId;
        this.logged_in_company_id = this.session.getLoggedInCompanyId();
        this.parent_company_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
        this.page_title = "";
        this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
        this.expandSellerDetails(true);
        this.expandBillingDetails(true);
        this.expandDeliveryDetails(true);
        this.readOnly = true;
        this.state.step = 2;
        this.page_title += " " + window.getLabelLiteral("RFQ_TITLE");

        this.lineItemsHandler = new MultiRecHandler(this.session, details_fields, details_mapping, LINE_ITEMS_MREC);

    }

    expandSellerDetails = (flg) => {
        this.aria_expanded_seller = flg;
        this.show_seller = (this.aria_expanded_seller) ? "show" : "";
        this.aria_collapsed_seller = (this.aria_expanded_seller) ? "expanded" : "collapsed";
    }

    expandBillingDetails = (flg) => {
        this.aria_expanded_billing = flg;
        this.show_billing = (this.aria_expanded_billing) ? "show" : "";
        this.aria_collapsed_billing = (this.aria_expanded_billing) ? "expanded" : "collapsed";
    }

    expandDeliveryDetails = (flg) => {
        this.aria_expanded_delivery = flg;
        this.show_delivery = (this.aria_expanded_delivery) ? "show" : "";
        this.aria_collapsed_delivery = (this.aria_expanded_delivery) ? "expanded" : "collapsed";
    }

    initialState = getInitialState(header_fields);

    state = {
        data: deepCopy(this.initialState),
    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                selectData: this.rrfq, error
            });
        else console.log("LOADED AS A PARENT");
    }

    fetch = async (rrfqId) => {
        return await quotationServiceRoutines.fetchReceivedRFQ(this.session, rrfqId);
    }

    loadSellerAddress = (data) => {
        data.seller_address = formatAddress(this.rrfq.header.seller_address);
    }

    loadBillingAddress = (data) => {
        data.billing_address = formatAddress(this.rrfq.header.billing_address);
    }

    loadDeliveryAddress = (data) => {
        data.delivery_address = formatAddress(this.rrfq.header.delivery_address);
    }

    componentDidMount = async () => {
        let newData;
        try {
            this.rrfq = await this.fetch(this.rrfqId);

            newData = deepCopy(this.state.data);
            copyDataFieldsToFormFields(header_mapping, this.rrfq.header, newData);
            newData = this.lineItemsHandler.dataArrayToFormFields(this.rrfq.details, newData);

            this.loadSellerAddress(newData);
            this.loadBillingAddress(newData);
            this.loadDeliveryAddress(newData);

            this.setState({ loading: false, data: newData });

        }
        catch (e) {
            console.log(this.action);
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            this.backHandler(this.action, this.error);
        }
    }

    getDetailsHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key}>{window.getLabelLiteral("SELLER_ITEM_ID")}</th>
                <th key={++key}>{window.getLabelLiteral("SELLER_ITEM_DESCRIPTION")}</th>
                <th key={++key}>{window.getLabelLiteral("FROM_DATE")}</th>
                <th key={++key}>{window.getLabelLiteral("TO_DATE")}</th>
                <th key={++key}>{window.getLabelLiteral("QUANTITY")}</th>
                <th key={++key}>{window.getLabelLiteral("UNIT_OF_MEASURE")}</th>
            </tr>
        );
    }

    getDetailsRow = (rowData, index) => {
        const i = index - 1;

        let key = 0;
        let r = <React.Fragment key={index}>
            <tr key={++key}>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.seller_item_id} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "75%" }}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.seller_item_description} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{width:"95%"}}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.item_delivery_from_date} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "85%" }} disabled={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.item_delivery_to_date} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "85%" }} disabled={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.requested_quantity} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        formattingInputs={{ minimumFractionDigits: 0, maximumFractionDigits: 6 }}
                        style={{ width: "60%", textAlign:"right"}} readOnly={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.unit_of_measure} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{width:"100%"}}
                        disabled={this.readOnly} />
                </td>
            </tr>
        </React.Fragment>
        return r;
    }

    data_render = () => {
        let key = 0;

        let datablk = (
            <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="col-sm-12 h6 text-left">
                        {this.getBreadcrumb(["View Received request for quotation"])}
                    </div>
                    <hr></hr>
                    <div className="col-sm-6" >
                        <FormField
                            field={header_fields.buyer_org_name}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        &nbsp;
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.rfq_number}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.rfq_issue_date}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.description}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.currency}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.rfq_valid_from_date}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.rfq_valid_to_date}
                            style={{}}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.rfq_contact_user_name}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        &nbsp;
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.rfq_contact_email_id}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.rfq_contact_phone_num}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>

                    <div className="col-sm-6 mt-3 mb-3 ">
                        <Attachments
                            field={header_fields.attachments}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                            fileAttributeRequired={true}
                        />
                    </div>

                    <div className="accordion accordion-flush" id="sellerdetails">
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="sellerdetailsdata">
                                <div className="col-sm-12 h4 text-left">
                                    <div className="col-sm-2">
                                        <button className=
                                            {`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_seller}`}
                                            id="collapseOne-button"
                                            style={{
                                                color: "black",
                                                backgroundColor: "white",
                                            }}
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded={this.aria_expanded_seller}
                                            aria-controls="collapseOne">
                                            <h6>Seller Details</h6>
                                        </button>
                                    </div>
                                </div>
                            </h2>

                            <div id="collapseOne" className={`accordion-collapse collapse ${this.show_seller}`}
                                aria-labelledby="sellerdetailsdata" data-bs-parent="#sellerdetails">
                                <div className={`accordion-body mx-0 gx-0 px-0`} id="sellerdetails-1">
                                    <div className="row    p-0 m-0 g-0 ">
                                        <div className="col-sm-6" >
                                            <FormField
                                                field={header_fields.seller_org_name}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.seller_address}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.seller_contact_user_name}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.seller_contact_email_id}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.seller_contact_phone_num}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion accordion-flush" id="billingdetails">
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="billingdetailsdata">
                                <div className="col-sm-12 h4 text-left">
                                    <div className="col-sm-2">
                                        <button className=
                                            {`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_billing}`}
                                            id="collapseTwo-button"
                                            style={{
                                                color: "black",
                                                backgroundColor: "white",
                                            }}
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded={this.aria_expanded_billing}
                                            aria-controls="collapseTwo">
                                            <h6>Billing Details</h6>
                                        </button>
                                    </div>
                                </div>
                            </h2>
                            <div id="collapseTwo" className={`accordion-collapse collapse ${this.show_billing}`}
                                aria-labelledby="billingdetailsdata" data-bs-parent="#billingdetails">
                                <div className={`accordion-body mx-0 gx-0 px-0`} id="billingdetails-1">
                                    <div className="row    p-0 m-0 g-0 ">
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.billing_address}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.billing_contact_user_name}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.billing_contact_email_id}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.billing_contact_phone_num}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion accordion-flush" id="deliverydetails">
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="deliverydetailsdata">
                                <div className="col-sm-12 h4 text-left">
                                    <div className="col-sm-2">
                                        <button className=
                                            {`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_delivery}`}
                                            id="collapseThree-button"
                                            style={{
                                                color: "black",
                                                backgroundColor: "white",
                                            }}
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded={this.aria_expanded_delivery}
                                            aria-controls="collapseThree">
                                            <h6>Delivery Details</h6>
                                        </button>
                                    </div>
                                </div>
                            </h2>
                            <div id="collapseThree" className={`accordion-collapse collapse ${this.show_delivery}`}
                                aria-labelledby="deliverydetailsdata" data-bs-parent="#deliverydetails">
                                <div className={`accordion-body mx-0 gx-0 px-0`} id="deliverydetails-1">
                                    <div className="row    p-0 m-0 g-0 ">
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.delivery_address}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.delivery_contact_user_name}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.delivery_contact_email_id}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.delivery_contact_phone_num}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <MultiRec
                            key={++key}
                            getRow={this.getDetailsRow}
                            getHeader={this.getDetailsHeader}
                            data={this.rrfq.details}
                            tfootStyle={{ borderTop: "0.1rem solid" }}
                            tbodyStyle={{ borderTop: "2.5px solid" }}
                        />
                    </div>
                </div>

                {(this.state.step === 2) &&
                    <div className="row  p-0 m-0 g-0 " >
                        <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                            <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                        </div>
                    </div>
                }
            </div>
        );

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    loading_render = () => {
        let buttons = [];

        let datablk = <div className="container border border-0 pt-0 m-0 ">
            <div className="row  p-0 m-0 g-0 " >
                <div className="col-sm-12 h4 text-center" >{this.page_title}</div>
                <div className="d-flex justify-content-evenly">
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

        </div>

        let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    render = () => {
        if (this.state.loading) {
            return this.loading_render();
        }
        else {
            return this.data_render();
        }
    }

}


export default ViewReceivedRFQ;
